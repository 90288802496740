import PropTypes from 'prop-types'
import { commonPageProps, location } from '@proptypes'
import { getLaunchDarklyFlag } from '#api/flags'
import {
  anonymousIdHeader,
  strategicalShopFinderFlag,
  clickAndCollectShutter,
  showUberallWidget
} from '#constants'
import { getMarketFromCtx } from '#src/common/lib/get-market'
import getCommonPageProps from '#src/common/init/get-common-props'
import { getGeocodeByLocale } from '#src/store-finder/components/shop-finder-container/api'
import { getShopFinderRegionsFromMarket } from '#src/shop-finder/util/ui'
import NewShopFinder from '#src/shop-finder'
import PageLayout from '#src/page/components/layout'

import LegacyShopFinder from '#src/store-finder/components/shop-finder-container'
import { parseCookies } from 'nookies'
import usePromotions from '#src/common/hooks/usePromotions'
import { InPagePromotion } from '#src/common/app/components/announcements/promotion'
import UberallShopFinderPage from '#src/shop-finder/components/uberall-shop-finder'

export const getServerSideProps = async ctx => {
  const { alternateLanguagePages, locale } = ctx
  const cookies = parseCookies(ctx)
  const user = cookies[anonymousIdHeader]
  const market = getMarketFromCtx(ctx)
  const [
    commonProps,
    shutterClickAndCollect,
    showStrategicalShopFinder,
    enableUberallWidget
  ] = await Promise.all([
    getCommonPageProps(locale, '/shop-finder'),
    getLaunchDarklyFlag(clickAndCollectShutter, user, market?.id),
    getLaunchDarklyFlag(strategicalShopFinderFlag, user, market?.id),
    getLaunchDarklyFlag(showUberallWidget, user, market?.id)
  ])
  const { fitBounds, center } = getGeocodeByLocale(locale)
  const shopFinderLocaleRegions = getShopFinderRegionsFromMarket(market, locale)

  return {
    props: {
      ...commonProps,
      ...(alternateLanguagePages && { alternateLanguagePages }),
      defaultCenter: center,
      defaultFitBounds: fitBounds,
      shopFinderLocaleRegions,
      showClickAndCollect: !shutterClickAndCollect,
      showStrategicalShopFinder:
        showStrategicalShopFinder && locale.toLowerCase() === 'en-gb',
      enableUberallWidget
    }
  }
}

const ShopFinderPage = ({
  defaultCenter,
  defaultFitBounds,
  settings,
  isTransactional,
  showStrategicalShopFinder,
  showClickAndCollect,
  shopFinderLocaleRegions,
  enableUberallWidget,
  isStoreFinderBundleLoaded,
  setIsStoreFinderBundleLoaded
}) => {
  const ShopFinder = showStrategicalShopFinder
    ? NewShopFinder
    : LegacyShopFinder
  const { dataForInPagePromo, currentPromotion, showInPagePromo } =
    usePromotions({ promotions: settings.promotions, isTransactional })
  const inPagePromo =
    showInPagePromo && currentPromotion ? (
      <InPagePromotion
        {...currentPromotion}
        trackingCode={dataForInPagePromo.trackingCode}
        type='in-page'
      />
    ) : null
  return enableUberallWidget ? (
    <UberallShopFinderPage
      settings={settings}
      isStoreFinderBundleLoaded={isStoreFinderBundleLoaded}
      setIsStoreFinderBundleLoaded={setIsStoreFinderBundleLoaded}
    />
  ) : (
    <PageLayout settings={settings}>
      <ShopFinder
        defaultCenter={defaultCenter}
        defaultFitBounds={defaultFitBounds}
        googleMapsJavaScriptApiKey={settings.googleMapsJavaScriptApiKey}
        displayClickAndCollect={showClickAndCollect}
        localeRegions={shopFinderLocaleRegions}
        inPagePromo={inPagePromo}
        showPOI={showStrategicalShopFinder}
      />
    </PageLayout>
  )
}

ShopFinderPage.propTypes = {
  defaultCenter: location.isRequired,
  defaultFitBounds: PropTypes.shape({
    ne: location,
    sw: location
  }).isRequired,
  showPOI: PropTypes.bool,
  ...commonPageProps
}

export default ShopFinderPage
