import ShopFinderPage, {
  getServerSideProps as _getServerSideProps
} from '#src/shop-finder/pages/shop-finder-page'
import withSSRLoggingContext from '#src/common/lib/with-ssr-logging-context'
import { withAltLanguagePages } from '#src/common/lib/with-alt-lang-pages'
import pipe from 'ramda/src/pipe'

const pages = [
  { locale: 'en-GB', href: 'https://www.pret.co.uk/en-GB/shop-finder' },
  { locale: 'en-US', href: 'https://www.pret.com/en-US/shop-finder' }
]

export const getServerSideProps = pipe(
  withSSRLoggingContext,
  withAltLanguagePages(pages)
)(_getServerSideProps)

export default ShopFinderPage
