import { useEffect, useLayoutEffect, useState } from 'react'
import { UberallContainer } from '../../styles'
import { useRouter } from 'next/router'
import { uberallWidgetTitle, uberallDataKeyDefault } from './constants'
import { getLogger } from '#src/api/logger-with-context'

const UberallShopFinderPage = ({
  settings,
  isStoreFinderBundleLoaded,
  setIsStoreFinderBundleLoaded
}) => {
  const [widgetOriginURL, setWidgetOriginURL] = useState('shop-finder')
  const router = useRouter()
  const log = getLogger()
  const language =
    router.locale === 'zh-HK' ? 'zh_TW' : router.locale?.split('-')[0] || ''

  // if failed to load data, assigning general data-key
  let uberallDataKey = uberallDataKeyDefault
  uberallDataKey =
    settings && settings.uberallDataKey && settings.uberallDataKey

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      return () => {
        window.UberallStorefinderUnmount()
      }
    }, [])
  }

  useEffect(() => {
    if (!isStoreFinderBundleLoaded) {
      try {
        window.UberallStorefinderStart()
      } catch (error) {
        log.info('ERROR: ', error)
      }
      setIsStoreFinderBundleLoaded(true)
    } else {
      window.UberallStorefinderRestart()
    }

    const basePath =
      window.location.origin + '/' + router.locale + '/shop-finder'
    setWidgetOriginURL(basePath)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UberallContainer>
      <div
        data-testid='uberall-shop-finder'
        id='store-finder-widget'
        data-key={uberallDataKey}
        data-language={language}
        data-trackevents='true'
        data-askforlocationonload='false'
        data-filters='services'
        data-buttonbgcolor='#900028'
        data-buttontextcolor='#fff'
        data-linkcolor='#696969'
        data-showzipcity='true'
        data-showattribution='false'
        data-showheader='false'
        data-widgetoriginurl={widgetOriginURL}
        data-showbrands='true'
        data-showservices='true'
        data-title={uberallWidgetTitle}
        style={{ minHeight: '600px' }}
      />
    </UberallContainer>
  )
}

export default UberallShopFinderPage
